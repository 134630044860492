*, *:before, *:after {
  box-sizing: border-box;
}

body {
   /* global 94%+ browsers support */
  background: linear-gradient(319deg, rgba(0, 255, 235, 1) 0%, rgba(7, 154, 187, 1) 100%);
  background-attachment: fixed;
  color: rgb(255, 255, 255, 0);
}

.App {
  font-family: sans-serif;
  text-align: center;
  margin: 0.2rem auto;
}

@font-face {
  font-family: "Nunito-Bold";   /*Can be any text*/
  src: local("NunitoBold"),
    url("./fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Regular";   /*Can be any text*/
  src: local("NunitoRegular"),
    url("./fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-ExtraBold";   /*Can be any text*/
  src: local("NunitoExtraBold"),
    url("./fonts/Nunito-ExtraBold.ttf") format("truetype");
}

a{
  text-decoration: none;
}

.example {
  border: 8px solid;
  letter-spacing: 0px;
}

.toptitle {
  font-family: "Nunito-Bold"; 
  font-size: 1.4rem;
  color: teal;
}

.btntext {
  font-family: "Nunito-Bold";
  text-align: left;
  font-size: 1.2rem;
}

.btntext:hover {
border-color: #7fffd4;
color:#7fffd4;
}

.example div {
  border: 0px solid;
}
.example div div {
  color: gold;
  color: #546e7a;
  border-radius: 16px;
  border: 3px solid teal;
}
.example h1 {
  color: teal;
  font-weight: 300;
  font-size: 1.5rem;
}
.example h3 {
  font-size: 1rem;
  font-weight: 300;
}

.goto {
  margin: 2rem 0 5rem;
}
.goto a {
  color: skyblue;
  letter-spacing: 1px;
  font-style: italic;
}
